import React from 'react'
import { ProductData } from '../../types'
import Collapse from '../Collapse'
import MotiveEdit from '../cms/MotiveEdit'
import { useTranslation } from 'react-i18next'

interface MotifDescriptionProps {
  productData: ProductData
}

const MotifDescription: React.FC<MotifDescriptionProps> = ({ productData }) => {
  const { t } = useTranslation('translation')
  return (
    <div className="row">
      <Collapse
        headline={<h2>{t('Motivbeschreibung')}</h2>}
        internalName="motif_description"
      >
        <>
          <MotiveEdit motiveId={productData.id} />
          <p className="mt-4">{productData.description}</p>
        </>
      </Collapse>
    </div>
  )
}

export default MotifDescription
