import React from 'react'
import Text, { TextTag } from '@pixum/text'
import * as styles from './style.module.css'

const CollapseContent: React.FC = ({ children }) => (
  <Text className={styles.collapse__content} tag={TextTag.DIV}>
    {children}
  </Text>
)

export default CollapseContent
