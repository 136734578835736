import React, { useState } from 'react'
import { CollapseProps } from './types'
import * as styles from './style.module.css'
import Headline from './Headline'
import Content from './Content'

/* imported from the pixum library 
modified the headline type from string to React.ReactNode
changed css to modules
*/

const Collapse: React.FC<CollapseProps> = ({
  internalName,
  headline,
  children,
  expanded = false,
  onClick,
}) => {
  const [checked, setChecked] = useState(expanded)

  const handleClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    onClick?.(e)
    setChecked(!checked)
  }

  return (
    <section className={styles.collapse} id={internalName}>
      <Headline
        expanded={checked}
        headline={headline}
        internalName={internalName}
        onClick={handleClick}
      />
      <Content>{children}</Content>
    </section>
  )
}

export default Collapse
