import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMaterialsData } from '../../lib/materials_hook'
import { ProductData, ProductMaterial } from '../../types'
import { LinearProgress } from '@mui/material'
import ProductPreview from './Preview'
import { useAlternativesData } from '../../lib/products/alternatives_hook'
import { isConnectedMaterialBundle } from '../../utils/Mapping'

interface CrosslinksSameMaterialProps {
  productData: ProductData
  currentMaterial: string
  variant?: string
}

const CrosslinksSameMaterial = (props: CrosslinksSameMaterialProps) => {
  const alternativesData = useAlternativesData({
    motiveId: props.productData.id,
    material: props.currentMaterial,
  })
  const { t } = useTranslation('translation')

  const materialsData = useMaterialsData()

  if (typeof alternativesData === 'undefined' || materialsData.length == 0) {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <LinearProgress />
            </div>
          </div>
        </div>
      </>
    )
  } else {
    const productsData: ProductData[] = Object.values(alternativesData)

    if (productsData.length == 0) {
      return <></>
    } else {
      return (
        <>
          <div className="row" style={{ paddingBottom: 0 }}>
            <div className="col-md">
              <h2>{t('Vielleicht interessierst du dich für...')}</h2>
            </div>
          </div>
          <div className="row">
            {productsData.map((productData: ProductData, i) => (
              <div className="col-6 col-sm-4" key={'product_preview_' + i}>
                <ProductPreview
                  material={props.currentMaterial}
                  materials={materialsData.filter(
                    (materialData: ProductMaterial) => {
                      return isConnectedMaterialBundle(
                        materialData,
                        props.currentMaterial,
                      )
                    },
                  )}
                  productData={productData}
                  variant={props.variant}
                />
              </div>
            ))}
          </div>
        </>
      )
    }
  }
}

export default CrosslinksSameMaterial
