import React, { ReactElement, useEffect } from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import {
  ProductMaterial,
  ParsedSku,
  CartItem,
  ProductData,
  GlobalSale,
} from '../../../types'
import MaterialSelect from './MaterialSelect'
import Grid from '@mui/material/Grid'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import BrushIcon from '@mui/icons-material/Brush'
import LoyaltyIcon from '@mui/icons-material/Loyalty'
import BuyButtonPage from './BuyButtonUpsellingPage'
import { formatPrice } from '../../../utils/utils'
import DeliveryTimeInformation from '../DeliveryTimeInformation'
import MaterialGroupSelect from './MaterialGroupSelect'
import {
  getCartCountry,
  getPriceCountry,
} from '../../../utils/CountrySelection'
import { getFreeShipping } from '../../../utils/CartUtils'
import RatioSelect from './RatioSelect'
import { useTranslation } from 'react-i18next'
import { useSale } from '../../../lib/global/sale_hook'
import CrosslinksButton from '../CrosslinksButton'
import ArtworksInsideButton from '../ArtworksInsideButton'
import { trackProductView } from '../../../utils/ArtboxoneTracking'
import { buildSku } from '../../../utils/ProductUtils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      '.configuration--button-classic': {
        display: 'block',
      },
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
  }),
)

export type Crosstype = 'wallart' | 'calendar'

interface ProductConfiguratorProps {
  filteredMaterials: ProductMaterial[]
  productInformation: ParsedSku
  material: string
  productData: ProductData
  crosstype: Crosstype
  IsConfigurationDialogOpenable: boolean
  openConfiguratorDialogButton: ReactElement
  setCrosslinkExpansionStateOn: () => unknown
  showCrosslinkButton: boolean
}

const ProductConfigurator: React.FC<ProductConfiguratorProps> = ({
  filteredMaterials,
  productInformation,
  material,
  productData,
  crosstype,
  IsConfigurationDialogOpenable,
  openConfiguratorDialogButton,
  setCrosslinkExpansionStateOn,
  showCrosslinkButton,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('translation')
  const priceCountry = getPriceCountry(getCartCountry())

  const matchingMaterials = filteredMaterials.filter(
    (materialData: ProductMaterial) => {
      return materialData.iid == productInformation.materialIId
    },
  )
  const currentMaterial = matchingMaterials[matchingMaterials.length - 1]

  const { salePrice, price, saleText } = getPriceData(currentMaterial)
  const outOfStock = currentMaterial?.out_of_stock

  const cartItem: CartItem = {
    motiveId: productInformation.motiveId,
    material: material,
    materialIId: productInformation.materialIId,
    count: 1,
    inserted: 0,
    ratio: productData.ratio,
  }

  const showClassicBuyButton = IsConfigurationDialogOpenable ? 'none' : 'block'

  // Track the product configuration view
  useEffect(() => {
    if (window && window._jts) {
      const productSku = buildSku(material, productInformation.motiveId)
      trackProductView({
        productSku,
        productName: productData.name,
        materialId: productInformation.materialIId,
        motiveId: productInformation.motiveId,
        price: price,
      })
    }
  }, [productData])

  const minPriceForFreeShipping = getFreeShipping(priceCountry)

  return (
    <Grid
      alignItems="center"
      className={classes.root}
      container
      direction="column"
      spacing={2}
    >
      <Grid item xs={12}>
        {salePrice > 0 && price && price > 0 && (
          <>
            <span
              className="pesPriceSmall"
              style={{
                textAlign: `center`,
                textDecoration: `line-through`,
                color: `gray`,
              }}
            >
              {formatPrice(price)}
            </span>
            <br />
            <span
              className="pesPrice"
              style={{ textAlign: `center`, color: `red` }}
            >
              {formatPrice(salePrice)}
            </span>
          </>
        )}

        {salePrice == 0 && price && price > 0 && (
          <>
            <span className="pesPrice" style={{ textAlign: `center` }}>
              {formatPrice(price)}
            </span>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <MaterialGroupSelect
          currentMaterial={currentMaterial}
          material={material}
          materials={filteredMaterials}
          productData={productData}
          productInformation={productInformation}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialSelect
          currentMaterial={currentMaterial}
          material={material}
          materials={filteredMaterials}
          productData={productData}
          productInformation={productInformation}
        />
      </Grid>

      <Grid className="cta_pes_outer" data-test="ups-add2cart" item xs={12}>
        {openConfiguratorDialogButton && (
          <div className="configuration--dialog-button">
            {openConfiguratorDialogButton}
          </div>
        )}

        <div
          className="configuration--button-classic"
          style={{ display: `${showClassicBuyButton}` }}
        >
          <BuyButtonPage
            cartItem={cartItem}
            material={currentMaterial}
            outOfStock={outOfStock}
            productData={productData}
            text={t('In den Warenkorb')}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <p style={{ textAlign: `center`, fontSize: `0.8rem` }}>
          {t('Inkl. MwSt., zzgl. Versand')}
          <br />
          <DeliveryTimeInformation materialIId={currentMaterial?.iid} />
        </p>
      </Grid>
      <Grid item xs={12}>
        <ul className="hideBulletinPoints">
          {saleText !== '' && (
            <li>
              <LoyaltyIcon /> * {saleText}
            </li>
          )}
          <li>
            <BrushIcon /> {t('individuell für dich produziert')}
          </li>
          {minPriceForFreeShipping !== -1 && (
            <li>
              <LocalShippingIcon />
              {' '}
              {`${t('Versandkostenfrei ab')} ${formatPrice(minPriceForFreeShipping)}`}
            </li>
          )}
          <li>
            <InsertEmoticonIcon /> {t('Jeder Kauf unterstützt einen Künstler')}
          </li>
        </ul>
      </Grid>

      <Grid className="cta_pes_outer" item xs={12}>
        <RatioSelect
          currentMaterial={currentMaterial}
          materialgroup={material}
          productData={productData}
        />
      </Grid>
      <Grid className="cta_pes_outer" item xs={12}>
        {showCrosslinkButton && crosstype === 'calendar' && (
          <ArtworksInsideButton setState={setCrosslinkExpansionStateOn} />
        )}
        {showCrosslinkButton && crosstype === 'wallart' && (
          <CrosslinksButton setState={setCrosslinkExpansionStateOn} />
        )}
      </Grid>
    </Grid>
  )
}

export default ProductConfigurator

function getPriceData(currentMaterial: ProductMaterial) {
  const priceCountry = getPriceCountry(getCartCountry())

  let price = currentMaterial?.price[priceCountry]
  const priceUvp =
    typeof currentMaterial?.priceUvp !== 'undefined'
      ? currentMaterial?.priceUvp[priceCountry]
      : price

  let salePrice = 0.0
  let saleText = ''
  const sale: GlobalSale | undefined = useSale()

  if (sale !== undefined && price !== undefined) {
    const salePercent = sale.sale
    salePrice = (price / 100) * (100 - salePercent)
    salePrice = Math.round((salePrice + Number.EPSILON) * 100) / 100
    saleText = '' + sale.text
  } else if (price != priceUvp) {
    salePrice = price ? price : 0.0
    price = priceUvp
  }

  return { salePrice, price, saleText }
}
