import React from 'react'
import RightArrow from '@pixum/svg-icon/lib/Arrows/ForwardIos'
import { CollapseHeadlineProps } from './types'
import * as styles from './style.module.css'

const CollapseHeadline: React.FC<CollapseHeadlineProps> = ({
  headline,
  expanded,
  onClick,
  internalName,
}) => (
  <>
    <input
      checked={!!expanded}
      id={`collapse-${internalName}`}
      onClick={onClick}
      type="checkbox"
    />
    <label
      className={styles.collapse__headline}
      htmlFor={`collapse-${internalName}`}
    >
      {headline}
      <RightArrow />
    </label>
  </>
)

export default CollapseHeadline
