import React, { useContext, useEffect, useMemo } from 'react'
import SEO from '../generic/Seo'
import { renderImageUrl } from '../../lib/imageurl_api'
import {
  ProductMaterial,
  ProductImage,
  Artist,
  AlternateUrl,
 BreadcrumbLayer, ParsedSku , PageInformation, 
 ProductData} from '../../types'
import { navigate } from 'gatsby'
import Breadcrumb from '../artboxone/Breadcrumb'
import { useTranslation } from 'react-i18next'
import {
  mapInternalKey2Slug,
  mapSlug2InternalKey,
  mapInternalProductMaterial2InternalKey,
  getThumbnailReplacementType,
  buildCanonicalUrlsForPes,
  buildAlternateUrlsForPes,
  buildPesUrl,
} from '../../utils/UrlUtils'
import { isBrowser } from '../../utils/utils'
import { Helmet } from 'react-helmet'
import { MaterialDescription } from './MaterialDescription'
import { isConnectedMaterial } from '../../utils/Mapping'
import {
  getCartCountry,
  getCurrencyIso,
  getPriceCountry,
} from '../../utils/CountrySelection'
import CrosslinksSameMaterial from './CrosslinksSameMaterial'
import { buildSku, getCrosslinkProducts as getCrosslinkDisplayData } from '../../utils/ProductUtils'
import {
  setEnvironmentVariable,
  pushTmEvent,
} from '../layout/EnvironmentVariables'
import MotiveEdit from '../cms/MotiveEdit'
import fetchError from '../../lib/global/error_api'
import { env } from '../../../environment'
import JSONLD from '../generic/jsonld/JsonLd'
import { generateJsonLdForProduct } from '../generic/jsonld/Generators'
import withLocation from '../generic/withLocation'
import {
  localStorage_getItem,
  localStorage_setItem,
} from '../../utils/LocalStorageHelper'
import LastVisited, { LOCALSTORAGE_LAST_VISITED } from '../products/LastVisited'
import createMaterialFactory from './configurator/helpers/createMaterial'
import { getSizeFromProductName } from './configurator/helpers/createVariant'
import { withDataFetching } from './withDataFetchingWrapper'
import { ProductShowcaseRow } from './ProductShowcaseRow'
import './AmbientImageBoxStyle.css';
import FluidProductImage from './FluidProductImage'
import PageInformationContext from '../../context/PageInformationContext'
import MotifDescription from './MotifDescription'

export interface ProductConfigurationProps {
  material: string
  productSlug?: string
  sku: string
  ratio: number | undefined
  customText: string | undefined
  url: string
  location?: Location
  productData: ProductData
  materialsData: ProductMaterial[]
  artistsData: Artist[]
  robots: string
}

interface LastVisitedLocalStorageItem extends ProductData {
  material: string,
  variant: string,
}

const ProductConfiguration = (props: ProductConfigurationProps) => {
  const materialName = props.material
  const artistsData = props.artistsData
  const materialsData = props.materialsData
  const productData = props.productData
  const priceCountry = getPriceCountry(getCartCountry())
  const productInformation: ParsedSku = parseSku(props.sku)

  checkIfMotiveIdIsValid(props, productInformation)
  checkIfProductDataIsValid(productData, props)

  const filteredMaterialsData = getFilteredMaterialsData(materialsData, materialName, productData, priceCountry)
  checkIfFilteredMaterialsIsValid(materialsData, filteredMaterialsData)

  const materialIid = extractMaterialIidFromUrl(props)
  const chosenMaterial = getChosenMaterialDefaultMaterial(filteredMaterialsData, materialIid)
  updateProductInformation(productInformation, materialIid, chosenMaterial, filteredMaterialsData)

  const crosslinkDisplayData = getCrosslinkDisplayData(productData, props.material, true, materialsData)
  const materials = useMemo(() => {
    const materialFactory = createMaterialFactory(crosslinkDisplayData, priceCountry)
    const materials = materialFactory.getMaterialsWithVariants()

    return materials.reduce((result: Record<string, any>[], material) => {
      const variantsPerColor = material.variants.getInStockPerColor(
        chosenMaterial.price[priceCountry],
        getSizeFromProductName(chosenMaterial.name),
      )
      const hasVariants = Object.keys(variantsPerColor).length > 0

      if (hasVariants) {
        return [
          ...result,
          {
            name: material.name,
            productData: material.productData,
            signature: material.signature,
            variantsPerColor,
          },
        ]
      } else {
        return result
      }
    }, [])
  }, [crosslinkDisplayData, chosenMaterial])

  checkMissMatchInUrlSlugs(productData, productInformation, props)

  const sku = buildSku(materialName, productData.id)
  setLastVisitedLocalStorage(productData, props, chosenMaterial.material)

  const pesData = {
    motiveName: productData.name,
    materialPriceGross: chosenMaterial.price[priceCountry],
    materialName: materialName,
    currency: getCurrencyIso(priceCountry),
  }

  const imageUrls: string[] = getImageUrls(productData, chosenMaterial)
  const ambientImages = getAmbientImages(productData, chosenMaterial)
  const artistsToShow: Artist[] = getArtistsToShow(productData, artistsData)
  const breadcrumbLayers = getBreadcrumbLayers(props, productData)
  const canonicals = getCanonicals(props, productInformation)
  const { seoDescription, seoTitle } = getSeoData(materialName, productData)
  
  const pageInformation: PageInformation = {
    type: 'pes',
    material: props.material,
    variant: chosenMaterial.material,
  }
  const { resetCounter, setPageInformation } = useContext(PageInformationContext)
  useEffect(() => {
    setPageInformation(pageInformation)
  }, [resetCounter, chosenMaterial])

  return (
    <>
      <Helmet>
        <meta content={props.robots} name="robots" />
        {canonicals.map((canonical) => canonical)}
      </Helmet>
      {setEnvironmentVariable('sku', sku)}
      {setEnvironmentVariable('pageType', 'pes')}
      {setEnvironmentVariable('pes', pesData)}
      {pushTmEvent('artboxone-pes-loaded')}

      <SEO description={seoDescription} title={seoTitle} />
        <Breadcrumb layers={breadcrumbLayers} />

        <div className="container">

          <div className="row">
            <div className={'col title'} style={{ textAlign: 'center' }}>
              <h1 style={{ fontWeight: 'bold' }}>{productData.name}</h1>
              <MotiveEdit motiveId={productData.id} />
            </div>
          </div>

          <ProductShowcaseRow 
            ambientImages={ambientImages}
            artistsToShow={artistsToShow}
            chosenMaterial={chosenMaterial} 
            crosslinkDisplayData={crosslinkDisplayData} 
            filteredMaterialsData={filteredMaterialsData} 
            imageUrls={imageUrls}
            materialName={materialName} 
            materials={materials}
            productData={productData}
            productInformation={productInformation}>
          </ProductShowcaseRow>

          {productData.description !== '' && (
            <MotifDescription productData={productData}/>
          )}

          <MaterialDescription material={chosenMaterial} />
          <LastVisited url={props.url}/>
          <CrosslinksSameMaterial
            currentMaterial={props.material}
            productData={productData}
            variant={chosenMaterial.material}
          />
        </div>
        <JSONLD
          content={generateJsonLdForProduct(
            productData,
            sku,
            chosenMaterial,
            priceCountry,
            imageUrls,
            props.url,
          )}
        />
    </>
  )
}

export default withLocation(withDataFetching(ProductConfiguration))

export function parseSku(sku: string) {
  const result: ParsedSku = {
    motiveId: 0,
    slug: '',
    materialIId: 0,
  }

  if (typeof sku !== 'undefined') {
    const matches = sku.match(/([0-9]+)-(.+)/)

    if (matches?.[2]) {
      result.slug = matches[2]
      result.motiveId = parseInt(matches[1])
    }
  }

  return result
}

function updateProductInformation(productInformation: ParsedSku, materialIid: number, chosenMaterial: ProductMaterial, filteredMaterialsData: ProductMaterial[]) {
  productInformation.materialIId = materialIid

  if (chosenMaterial.legacy_id !== '0' &&
    productInformation.materialIId === 0) {
    productInformation.materialIId = chosenMaterial.iid
  } else {
    if (filteredMaterialsData !== undefined) {
      filteredMaterialsData.map((materialData: ProductMaterial) => {
        if (productInformation.materialIId === 0) {
          productInformation.materialIId = materialData.iid
        }
      })
    }
  }
}

function getSeoData(materialName: string, productData: ProductData) {
  const { t } = useTranslation('translation')
  const i18nMaterialName = t(materialName)
  const seoTitle = t('%s als %t bei artboxONE kaufen')
    .replace('%s', productData.name)
    // .replace('%t', chosenMaterial.name)
    .replace('%t', i18nMaterialName)

  let seoDescription = t('%s als %t bei artboxONE kaufen')
    .replace('%s', productData.name)
    // .replace('%t', chosenMaterial.name)
    .replace('%t', i18nMaterialName)

  if (productData.description !== '') {
    seoDescription = seoDescription + ' - ' + productData.description
  }

  return { seoDescription, seoTitle }
}

function getImageUrls(productData: ProductData, chosenMaterial: ProductMaterial) {
  const imageUrls: string[] = []

  for (const key of Object.keys(chosenMaterial.images)) {
    if (['small'].indexOf(key) === -1) {
      const thumbnail: ProductImage = {
        template: chosenMaterial.images[key],
        replacement: getThumbnailReplacementType(chosenMaterial.material),
      }

      const imageUrl = renderImageUrl({
        image: thumbnail,
        productData: productData,
      })

      if (imageUrl && key !== 'texture') {
        imageUrls.push(imageUrl)
      }
    }
  }

  return imageUrls
}

function getAmbientImages(productData: ProductData, chosenMaterial: ProductMaterial) {
  const res: JSX.Element[] = []

  const supportedMaterialGroups = ['poster', 'wooden', 'galleryprint', 'canvas', 'acryl', 'metalposter', 'alu', 'poster_metal_frame', 'poster_wooden_frame', 'poster_plastic']

  if(!supportedMaterialGroups.includes(chosenMaterial.materialgroup)) {
    return res
  }

  // filter ratio Panorama, not yet implemented
  if(chosenMaterial.ratio === 4) {
    return res
  }

  // filter material poster_white_plastic, not yet implemented
  if(chosenMaterial.material === 'poster_white_plastic'){
    return res
  }

  const size1 = parseInt(chosenMaterial.size_slug.split('-')[0])

  if (size1 < 30) { // no ambient for too small images
    return res
  }

  const backgroundImageType = size1 < 70 ? 'small' : 'large'
  const backgroundImages = {
  'large': 'https://pixum-assets.imgix.net/assets/testing/2024/ab1-ambiente-test-02.jpg?w=900&ar=1%3A1&fit=crop&crop=focalpoint&fp-y=0.45',
  'small': 'https://pixum-assets.imgix.net/assets/testing/2024/Test_Ambiente.jpg?w=900&ar=1%3A1&fit=crop&crop=bottom',
  }
  const backgroundImageUrl = backgroundImages[backgroundImageType]

  const smallProductImageExceptions = ['alu', 'poster_metal_frame', 'poster_wooden_frame', 'poster_plastic']
  const productImageType = smallProductImageExceptions.includes(chosenMaterial.materialgroup) ? 'small' : 'large'
  const productImage: ProductImage = {
    template: chosenMaterial.images[productImageType],
    replacement: getThumbnailReplacementType(chosenMaterial.material),
  }
  const productImageUrl = renderImageUrl({
    image: productImage,
    productData: productData,
  })

  const productUrlHash = buildPesUrl(
    chosenMaterial.material,
    chosenMaterial.url_slug,
    productData.id,
    productData.urlslug,
    productData.ratio,
  ).split('#')[1]
  const overlayImageClassName = `ambient-overlay-image_${productUrlHash},${chosenMaterial.materialgroup},`
  res.push(
    <div className={'AmbientImageBox'}>
      <div className={overlayImageClassName}
        style={{
          backgroundColor: '#abc',
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundSize: '100%'
      }}>
        <FluidProductImage
          className={'AmbientImage'}
          eagerLoad={true}
          imageUrl={productImageUrl}
          productData={productData}
        />
      </div>
    </div>
  )
  return res
}

function checkIfFilteredMaterialsIsValid(materialsData: ProductMaterial[], filteredMaterialsData: ProductMaterial[]) {
  if (materialsData.length > 0 && filteredMaterialsData.length === 0) {
    fetchError({
      data: 'No materials (' + JSON.stringify(props) + ')',
    })
    navigate('/404')
  }
}

function extractMaterialIidFromUrl(props: ProductConfigurationProps) {
  if (typeof props.productSlug !== 'undefined') {
    const materialFromSlug = parseInt('' + mapSlug2InternalKey(props.productSlug))

    if (materialFromSlug !== undefined && !isNaN(materialFromSlug)) {
      return materialFromSlug
    } else if (isBrowser()) {
      fetchError({
        data: 'Material "' +
          props.productSlug +
          '" not found -> Please check urls (' +
          JSON.stringify(props) +
          ')',
      })
    }
  }

  return 0
}

function checkIfMotiveIdIsValid(props: ProductConfigurationProps, productInformation: ParsedSku) {
  if (isBrowser() && productInformation.motiveId === 0) {
    if (props.sku !== '' && props.url !== '') {
      // fetchError({
      //   data:
      //     'Missing productinformation from sku -> 404 (' +
      //     JSON.stringify(props) +
      //     ')',
      // })
      // These are some Old urls
      navigate('/404') // OK
    } else {
      fetchError({
        data: 'Missing productinformation from sku -> ignore? (' +
          JSON.stringify(location.pathname) +
          ')',
      })

      navigate('/404')
    }
  }
}

function checkIfProductDataIsValid(productData: ProductData | null | undefined, props: ProductConfigurationProps) {
  if (productData === null) {
    fetchError({
      data: 'Could not load motive (' + JSON.stringify(props) + ')',
    })

    const redirectUrl = '/alternatives/' + props.material + '/' + props.sku
    navigate(redirectUrl)
  }
}

function checkMissMatchInUrlSlugs(productData: ProductData, productInformation: ParsedSku, props: ProductConfigurationProps) {
  if (isBrowser() &&
    typeof productData.urlslug !== 'undefined' &&
    productData.urlslug !== productInformation.slug) {
    fetchError({
      data: 'Mismatch urlslug from api "' +
        productData?.urlslug +
        '" vs url "' +
        productInformation.slug +
        '" (' +
        JSON.stringify(props) +
        ')',
    })

    // navigate('/404') // TODO navigate to new url or 404?
  }
}

function getChosenMaterialDefaultMaterial(filteredMaterialsData: ProductMaterial[], materialIid: number) {
  if (materialIid !== 0 && filteredMaterialsData.length > 0) {
    const foundMaterial = filteredMaterialsData.find(material => material.iid === materialIid)

    if (foundMaterial) {
      return foundMaterial
    }
  }


  let chosenMaterial: ProductMaterial

  if (filteredMaterialsData.length >= 2) {
    chosenMaterial = filteredMaterialsData[1]
  } else {
    chosenMaterial = filteredMaterialsData[0]
  }

  if (chosenMaterial.iid === 0) {
    chosenMaterial = filteredMaterialsData[0]
  }

  return chosenMaterial
}

function getFilteredMaterialsData(materialsData: ProductMaterial[], materialName: string, productData: ProductData, priceCountry: string) {
  const connectedMaterials = materialsData.filter((materialData: ProductMaterial) => {
    return isConnectedMaterial(materialData, materialName);
  })

  const materialsInProduct = connectedMaterials.filter((material: ProductMaterial) => {
    return productData.materialIIds.indexOf(material.iid) !== -1
  })

  const materialsWithValidPrice = materialsInProduct.filter((material: ProductMaterial) => {
    return material.price[priceCountry] > 0.02
  })

  return materialsWithValidPrice;
}

function setLastVisitedLocalStorage(productData: ProductData, props: ProductConfigurationProps, materialVariant: string) {
  const lastVisitedProductsInLocalStorage = localStorage_getItem(
    LOCALSTORAGE_LAST_VISITED
  )
  const lastVisitedProducts: LastVisitedLocalStorageItem[] = lastVisitedProductsInLocalStorage
    ? JSON.parse(lastVisitedProductsInLocalStorage)
    : []

  const lastVisited = lastVisitedProducts.filter(
    product => product.id !== productData?.id
  )

  const newLastVisitedProduct: LastVisitedLocalStorageItem = {
    material: props.material,
    variant: materialVariant,
    ...productData,
  }

  localStorage_setItem(
    LOCALSTORAGE_LAST_VISITED,
    JSON.stringify([...lastVisited, newLastVisitedProduct,])
  )
}

function getArtistsToShow(productData: ProductData, artistsData: Artist[]) {
  const artistsToShow: Artist[] = []

  productData?.artists?.map((artistId: number) => {
    const artists: Artist[] = artistsData.filter((artist: Artist) => {
      return artist.id === artistId
    })

    if (artists.length === 1) {
      artistsToShow.push(artists[0])
    }
  })
  return artistsToShow
}

function getCanonicals(props: ProductConfigurationProps, productInformation: ParsedSku) {
  const canonicals: JSX.Element[] = []
  buildCanonicalUrlsForPes(props.material, productInformation).map(
    (url: string) => {
      canonicals.push(
        <link href={url} key={'canonical-' + url} rel="canonical" />
      )
    }
  )

  if (env.getSeo() === '1') {
    buildAlternateUrlsForPes(props.material, productInformation).map(
      (alternateUrl: AlternateUrl) => {
        canonicals.push(
          <link
            href={alternateUrl.url}
            hrefLang={alternateUrl.locale}
            key={'alternate-' + alternateUrl.url}
            rel="alternate" />
        )
      }
    )
  }

  return canonicals
}

function getBreadcrumbLayers(props: ProductConfigurationProps, productData: ProductData | null | undefined) {
  const breadcrumbLayers: BreadcrumbLayer[] = []
  const { t } = useTranslation('translation')

  const productMaterialKey = mapInternalProductMaterial2InternalKey(props.material)
  let breadcrumbUrl = ''
  breadcrumbUrl += '/' + mapInternalKey2Slug(productMaterialKey)

  breadcrumbLayers.push({
    url: breadcrumbUrl,
    name: t(productMaterialKey),
  })

  breadcrumbUrl += '/' + mapInternalKey2Slug(props.material)
  breadcrumbLayers.push({
    url: breadcrumbUrl,
    name: t(props.material),
  })

  breadcrumbLayers.push({
    url: '',
    name: '' + productData?.name,
  })
  return breadcrumbLayers
}
